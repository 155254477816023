<template>
  <main class="mt-16 mb-8 mx-5">
    <div class="mb-8 ">
      <h1 class="uppercase font-medium">
        Preview
      </h1>
      <svg
        width="20"
        height="4"
        viewBox="0 0 20 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="-9.46895"
            y1="2.21242"
            x2="-7.84897"
            y2="9.88217"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#06B6CB" />
            <stop offset="1" stop-color="#253B95" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div>
      <!-- <img class="mx-auto" src="../../assets/images/barcode.png" /> -->
      <svg class="barcode mx-auto" />
    </div>
    <div class="text-center mt-6">
      <ul>
        <li v-if="hideSKU" class="font-medium">{{ selectedProduct.barcode }}</li>
        <li v-if="hideName"  class="text-sm font-medium text-primaryGray mt-2 capitalize">
          {{ selectedProduct.title }}
        </li>
        <li v-if="hidePrice" class="font-medium mt-4">
          {{ selectedProduct.price | formatPrice(false) }}
        </li>
      </ul>
    </div>
    <div
      class="overflow-scroll whitespace-no-wrap w-full justify-center mx-auto gap-10 mt-12"
    >
      <div
        v-for="(prod, i) in productWithVariant"
        :key="i"
        @click="selectProduct(prod)"
        :class="
          prod._id === selectedProduct._id
            ? 'border-solid border-primaryBlue'
            : ''
        "
        class="`w-32 w-32 text-center border inline-block rounded px-3 py-3 mx-2 mb-4 cursor-pointer"
      >
        <img class="block mx-auto" src="../../assets/images/barcode.png" />
        <span
          class="text-xs block mx-auto font-medium text-primaryGray capitalize"
          >{{ prod.title }}</span
        >
      </div>
      <div class="m-4">
        <p class="text-xs text-gray-600">
          <span class="text-red-600">*</span> Only products with barcode are displayed.
        </p>
      </div>
    </div>
  </main>
</template>

<script>
import htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
import JsBarcode from 'jsbarcode';
import { jsPDF } from "jspdf";

export default {
  name: "PreviewModal",
  props: {
    products: {
      type: Array,
      default: [],
    },
    hidePrice: {
      type: Boolean
    },
    hideName: {
      type: Boolean
    },
    hideSKU: {
      type: Boolean
    },
  },
  data() {
    return {
      labelProducts: null,
      selectedProduct: null,
    };
  },
  computed: {
    productWithVariant() {
      const arr = [];
      this.labelProducts.forEach((product) => {
        if (product.variation) {
          product.varieties.forEach((variant) => arr.push(variant));
        } else {
          arr.push(product);
        }
      });
      return arr.filter((p) => p.barcode !== "");
    },
  },
  created() {
    this.labelProducts = this.products;
    this.selectedProduct = this.labelProducts[0];
    this.generateBarcode();
  },
  methods: {
    selectProduct(product) {
      this.selectedProduct = product;
      this.generateBarcode();
    },
    generateBarcode() {
      setTimeout(() => {
        JsBarcode(".barcode", this.selectedProduct.barcode, {
          displayValue: false,
        });
      }, 20);
    },
  },
};
</script>

<style scoped></style>
