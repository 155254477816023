<template>
  <main class="mt-16 mb-4 mx-5">
    <trac-loading v-if="isLoading"></trac-loading>
    <div class="mb-8 print-hide">
      <h1 class="uppercase font-medium print-hide">
        Choose <span class="font-bold">Label Format</span>
      </h1>
      <svg
        width="20"
        height="4"
        viewBox="0 0 20 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="-9.46895"
            y1="2.21242"
            x2="-7.84897"
            y2="9.88217"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#06B6CB" />
            <stop offset="1" stop-color="#253B95" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="text-center mt-6 pb-6 bg-white label-to-print">
      <div>
        <!-- <img class="mx-auto" src="../../assets/images/barcode.png" /> -->
        <svg class="barcode mx-auto" />
      </div>
      <div class="">
        <p v-if="hideSKU" class="font-medium">{{ selectedProduct.barcode }}</p>
        <p v-if="hideName" class="text-sm font-medium text-primaryGray mt-2 capitapze">
          {{ selectedProduct.title }}
        </p>
        <p v-if="hidePrice" class="font-medium mt-4 pb-6">
          {{ selectedProduct.price | formatPrice(false) }}
        </p>
      </div>
    </div>
    <div
    v-if="productWithVariant.length > 1"
      class="print-hide overflow-scroll whitespace-no-wrap w-full justify-center mx-auto gap-10"
    >
      <div
        v-for="(prod, i) in productWithVariant"
        :key="i"
        @click="selectProduct(prod)"
        :class="
          prod._id === selectedProduct._id
            ? 'border-solid border-primaryBlue'
            : ''
        "
        class="`w-32 w-32 text-center border inline-block rounded px-3 py-3 mx-2 mb-4 cursor-pointer"
      >
        <img class="block mx-auto" src="../../assets/images/barcode.png" />
        <span
          class="text-xs block mx-auto font-medium text-primaryGray capitalize"
          >{{ prod.title }}</span
        >
      </div>
    </div>
    <div class="m-4 print-hide" v-if="productWithVariant.length > 1">
      <p class="text-xs text-gray-600">
        <span class="text-red-600">*</span> Only products with barcode are
        displayed.
      </p>
    </div>
    <div class="flex flex-row gap-5 justify-end mt-6 print-hide">
      <div class="flex-column">
        <trac-button class="uppercase" @button-clicked="saveAsPNG"
          >Save as image</trac-button
        >
      </div>
      <div class="flex-column">
        <trac-button class="uppercase" @button-clicked="printLabel">Send to printer</trac-button>
      </div>
    </div>
  </main>
</template>

<script>
import html2canvas from "html2canvas";
import JsBarcode from "jsbarcode";
import { jsPDF } from "jspdf";
import { toPng, toSvg, toJpeg } from "html-to-image";

export default {
  name: "ChooseLabelFormat",
  props: {
    products: {
      type: Array,
      default: [],
    },
    hidePrice: {
      type: Boolean
    },
    hideName: {
      type: Boolean
    },
    hideSKU: {
      type: Boolean
    },
  },
  data() {
    return {
      isLoading: false,
      labelProducts: null,
      selectedProduct: null,
    };
  },
  computed: {
    productWithVariant() {
      const arr = [];
      this.labelProducts.forEach((product) => {
        if (product.variation) {
          product.varieties.forEach((variant) => arr.push(variant));
        } else {
          arr.push(product);
        }
      });
      return arr.filter((p) => p.barcode !== "");
    },
  },
  created() {
    this.labelProducts = this.products;
    this.selectedProduct = this.labelProducts[0];
    this.generateBarcode();
  },
  methods: {
    printLabel() {
      print();
    },
    selectProduct(product) {
      this.selectedProduct = product;
      this.generateBarcode();
    },
    generateBarcode() {
      setTimeout(() => {
        JsBarcode(".barcode", this.selectedProduct.barcode, {
          displayValue: false,
        });
      }, 20);
    },
    saveAsPNG() {
      this.isLoading = true;
      const barcodeLabel = document.querySelector(".label-to-print");
      const title = this.selectedProduct.title;
      toPng(barcodeLabel)
        .then((dataUrl) => {
          var img = new Image();
          img.src = dataUrl;

          var a = document.createElement("a");
          a.href = img.src;
          a.download = title + ".png";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.isLoading = false;
        })
        .catch(function() {
          this.isLoading = false;
          // console.error('oops, something went wrong!', error);
        });
    },
  },
};
</script>

<style scoped>
@media print {
  .label-to-print {
    display: block;
  }
  .print-hide {
    display: none;
  }
}
</style>
