<template>
  <trac-loading v-if="loading"></trac-loading>
  <main v-else class="mr-4">
    <div class="wrapper print-hide">
      <div class="grid md:flex flex-row justify-between items-end">
        <ul class="flex-col mt-12 mb-8">
          <li><a href="#">Inventory Control</a></li>
          <li class="font-bold mt-4 text-2xl">Print Label</li>
        </ul>
        <div class="mb-8 flex flex-row gap-5">
          <trac-button
            class="uppercase"
            :disabled="selectedProducts.length === 0"
            @button-clicked="showLabelsToSave"
            >Save Label</trac-button
          >
          <trac-button
            :disabled="selectedProducts.length === 0"
            @button-clicked="showLabels"
            class="uppercase"
            >Preview</trac-button
          >
        </div>
      </div>
    </div>
    <div
      class="print-hide bg-white px-8 py-8 rounded-lg h-full overflow-scroll big-shadow"
    >
      <div class="mb-8">
        <trac-custom-header>
          <template slot="leading">Label</template>
          <template slot="trailing"> Settings</template>
        </trac-custom-header>
      </div>
      <div class="grid md:flex flex-row gap-5 md:gap-40 mb-8">
        <trac-toggle
          @newval="hidePrice = !hidePrice"
          :active="hidePrice"
          class="text-primaryBlue font-semibold"
          >Show Price</trac-toggle
        >
        <trac-toggle
          @newval="hideName = !hideName"
          :active="hideName"
          class="text-primaryBlue font-semibold"
          >Show Name</trac-toggle
        >
        <trac-toggle
          @newval="hideSKU = !hideSKU"
          :active="hideSKU"
          class="text-primaryBlue font-semibold"
          >Show SKU</trac-toggle
        >
      </div>
    </div>
    <div
      class="bg-white p-3 md:px-8 md:py-8 rounded-lg mt-5 h-full overflow-hidden big-shadow print-hide"
    >
      <div class="md:justify-between flex flex-row">
        <div class="grid md:flex gap-4 flex-row items-center w-full">
          <div class="relative w-full md:w-128 rounded-md flex items-center">
            <input
              type="text"
              placeholder="Search Items"
              class="input-shadow py-2 w-full pl-5 text-xs focus:outline-none leading-7 tracking-wide text-primaryGray"
              v-model="searchedItem"
            />
            <svg
              class="w-4 h-4 absolute right-0 mr-6"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="6.56705"
                cy="6.61686"
                r="5.39909"
                stroke="#253B95"
                stroke-width="1.06786"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.3156 13.3659L10.3799 10.4302"
                stroke="#253B95"
                stroke-width="1.06786"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <trac-dropdown
            :disabled="categories.length == 0"
            :menuItems="categories"
            v-model="category"
            class="mr-5 w-full"
          ></trac-dropdown>
        </div>
      </div>
      <div class="mt-8 border rounded-lg overflow-auto">
        <table class="w-full table-auto h-full overflow-auto">
          <thead class="bg-blue-100">
            <tr class="">
              <th class="text-left text-xs font-semibold p-4 uppercase">
                <trac-checkbox @check="selectAllProducts"></trac-checkbox>
              </th>
              <th class="text-left text-xs font-semibold p-4 uppercase">
                Item
              </th>
              <th class="text-left text-xs font-semibold p-4 uppercase">SKU</th>
              <th class="text-left text-xs font-semibold p-4 uppercase">
                Category
              </th>
              <th class="text-left text-xs font-semibold p-4 uppercase">
                Qty in stock
              </th>
              <th class="text-left text-xs font-semibold p-4 uppercase">
                Price
              </th>
            </tr>
          </thead>
          <tbody v-if="filteredItems.length">
            <tr
              :class="index % 2 === 0 ? '' : 'bg-gray-100'"
              v-for="(item, index) in filteredItems"
              class="hover:bg-gray-200 cursor-pointer"
              :key="index"
            >
              <td class="text-xs font-medium p-4">
                <trac-checkbox
                  @check="selectedItems($event, item)"
                  :value="item"
                  :isChecked="item.isSelected"
                ></trac-checkbox>
              </td>
              <td class="text-xs font-normal p-4 capitalize">
                {{ item.title }}
              </td>
              <td class="text-xs font-medium p-4">
                <p>{{ item.variation ? "" : item.barcode }}</p>
              </td>
              <td class="text-xs font-medium p-4 lowercase capitalize">
                {{ item.category }}
              </td>
              <td class="text-xs font-medium p-4">
                {{ item.stock }}
              </td>
              <td class="text-xs font-medium p-4">₦ {{ item.price }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-xs font-medium p-4" colspan="6">
                <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      There is no data available.
                    </div>
                  </trac-center-data>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <trac-modal v-if="previewModal" @close="previewModal = false">
      <PreviewModal
        :hidePrice="hidePrice"
        :hideName="hideName"
        :hideSKU="hideSKU"
        :products="selectedProducts"
      ></PreviewModal>
    </trac-modal>
    <trac-modal size="max-w-2xl" v-if="saveModal" @close="saveModal = false">
      <ChooseLabelFormat
        :hidePrice="hidePrice"
        :hideName="hideName"
        :hideSKU="hideSKU"
        :products="selectedProducts"
      ></ChooseLabelFormat>
    </trac-modal>
  </main>
</template>

<script>
import PreviewModal from "./PreviewModal";
import ChooseLabelFormat from "./ChooseLabelFormat";
export default {
  components: { ChooseLabelFormat, PreviewModal },
  data() {
    return {
      hidePrice: true,
      hideName: true,
      hideSKU: true,
      categories: [],
      previewModal: false,
      saveModal: false,
      items: [],
      loading: false,
      category: "all",
      searchedItem: "",
    };
  },

  methods: {
    selectAllProducts(event) {
      this.items = this.items.map((item) => {
        return { ...item, isSelected: event.checked };
      });
    },
    showLabels() {
      this.previewModal = true;
    },
    showLabelsToSave() {
      this.saveModal = true;
    },
    selectedItems(e, product) {
      this.items = this.items.map((item) => {
        if (item._id === product._id) {
          item = { ...item, isSelected: e.checked };
        }
        return item;
      });
    },
  },
  computed: {
    selectedProducts() {
      return this.items.filter((item) => item.isSelected);
    },
    filteredItems() {
      let searchedFilteredItems = [...this.items].filter((item) => {
        return item.title
          .toLowerCase()
          .includes(this.searchedItem.toLowerCase());
      });
      let filteredCat = [...searchedFilteredItems].filter((slew) => {
        return slew.category === this.category;
      });
      if (this.category == "all") {
        return searchedFilteredItems;
      } else {
        return filteredCat;
      }
    },
  },
  async created() {
    this.loading = true;
    let res = await this.$store.dispatch("FETCH_ALL_CATEGORIES");
    res.data
      ? (this.categories = [{ name: "all" }, ...res.data].filter((x) => {
          return x.name;
        }))
      : (this.categories = []);

    let resTwo = await this.$store.dispatch("FETCH_ALL_PRODUCTS");

    if (resTwo.status) {
      this.loading = false;
      if (resTwo.items) {
        this.items = resTwo.items.map((item) => {
          return { ...item, isSelected: false };
        });
      } else {
        this.items = [];
      }
    }
  },
};
</script>

<style scoped>
@media print {
  .label-to-print {
    display: block;
  }
  .print-hide {
    display: none;
  }
}
</style>
